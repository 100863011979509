import CategoryHeader from "../../../components/CategoryHeader";
import "../../../styles/gender2.css";
import React, { useState } from "react";
import Roulette from "../../../components/roulette";

function Gender2() {
  return (
    <div>
      <CategoryHeader
        headerText="Spin for your gender"
        pageNumber={2}
        category="gender"
      />

      <div className="date-picker-container">
        <Roulette />
      </div>
    </div>
  );
}

export default Gender2;
