import CategoryHeader from "../../../components/CategoryHeader";
import "../../../styles/date1.css";
import React, { useState } from "react";

function toFormattedDateString(inputDate) {
    return inputDate.toLocaleDateString('en-US', {
        weekday: 'long',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
}

function Date1() {
    const today = new Date();
    const [dateValue, setDateValue] = useState(today);

    const clickLargeDecrease = () => {
        setDateValue(new Date(1, 0, 0))
    }

    const clickSmallDecrease = () => {
        var currentDate = new Date(dateValue);
        var previousDay = new Date(dateValue);
        previousDay.setDate(currentDate.getDate() - 1);
        setDateValue(previousDay)
    }

    const clickSmallIncrease = () => {
        var currentDate = new Date(dateValue);
        var nextDay = new Date(dateValue);
        nextDay.setDate(currentDate.getDate() + 1);
        setDateValue(nextDay)
    }

    const clickLargeIncrease = () => {
        setDateValue(new Date("9999", 11, 31))
    }

  return (
    <div>
      <CategoryHeader
        headerText="Pick Your Birthday"
        pageNumber={1}
        category="date"
      />

      <div className="date-picker-container">
        <h3>{ toFormattedDateString(dateValue) }</h3>
        <div className="date-controls-container">
            <button onClick={clickLargeDecrease}>{"<<"}</button>
            <button onClick={clickSmallDecrease}>{"<"}</button>
            <input value="submit" type="submit" />
            <button onClick={clickSmallIncrease}>{">"}</button>
            <button onClick={clickLargeIncrease}>{">>"}</button>
        </div>
      </div>
    </div>
  );
}

export default Date1;
