import React, { useEffect, useState } from 'react';
import '../../../styles/number1.css'
import CategoryHeader from "../../../components/CategoryHeader";


function Number1() {
    const [selectedOption, setSelectedOption] = useState('');
    const [randomArray, setRandomArray] = useState([]);

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };

    useEffect(() => {
        const generateRandomArray = () => {
          const array = Array.from({ length: 1000 }, (_, index) => index + 1);

          // Fisher-Yates shuffle algorithm
          for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
          }

          setRandomArray(array);
        };

        generateRandomArray();
      }, []);

  return (
    <div>
      <CategoryHeader
        headerText="Choose a number"
        pageNumber={1}
        category="number" />
      <div>
      <div className='radio-button-container'>
      {randomArray.map((index) => (
        <label key={index} className='radio-button'>
          <input
            type="radio"
            value={`${index + 1}`}
            checked={selectedOption === `${index + 1}`}
            onChange={handleOptionChange}
          />
          {index + 1}
        </label>
      ))}
      </div>
      </div>
    </div>
  );
}

export default Number1;
