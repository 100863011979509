import { Link } from "react-router-dom";
import "../styles/NextButton.css"
import doneButton from "../assets/done.png"
import nextButton from "../assets/red_next.png"

function NextButton({ pageNumber, category, maxPageNumber }) {
    var routeString = "/" + category + "/" + (pageNumber+1);
    var routeToDone = false;

    if(pageNumber >= maxPageNumber) {
        routeString = "/done";
        routeToDone = true;
    }

    return (
        <div>
            <Link to={routeString}>
                {
                    routeToDone ?
                        <img src={doneButton} className="done-button" alt="done" />
                        :
                        <img src={nextButton} className="next-button" alt="next" />
                }
            </Link>
        </div>
    );
}

export default NextButton;
