import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import logo from "../src/assets/logo.svg";
import "../src/styles/App.css";
import Header from "../src/components/Header";
import Home from "./pages/Home"
import Categories from "./pages/Categories"
import Phone1 from "../src/pages/categories/phone/1"
import Phone2 from "../src/pages/categories/phone/2"
import Gender1 from "../src/pages/categories/gender/1"
import Gender2 from "../src/pages/categories/gender/2"
import Name1 from "../src/pages/categories/name/1"
import Number1 from "../src/pages/categories/number/1"
import Number2 from "../src/pages/categories/number/2"
import Date1 from "../src/pages/categories/date/1"
import Help from "./pages/Help";
import RealHelp from "./pages/RealHelp";
import Done from "./pages/Done";

function App() {
  return (
    <div className="App">

      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/help" element={<Help />} />
          <Route path="/real-help" element={<RealHelp />} />
          <Route path="/phone/1" element={<Phone1 />} />
          <Route path="/phone/2" element={<Phone2 />} />
          <Route path="/name/1" element={<Name1 />} />
          <Route path="/number/1" element={<Number1 />} />
          <Route path="/number/2" element={<Number2 />} />
          <Route path="/date/1" element={<Date1 />} />
          <Route path="/gender/1" element={<Gender1 />} />
          <Route path="/gender/2" element={<Gender2 />} />
          <Route path="/done" element={<Done />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
