import { Link } from 'react-router-dom'
import styles from '../../src/styles/RealHelp.css'
import StartButton from '../components/StartButton';

function RealHelp() {
    return (
            <div className='help-header'>
                <h1>Help!</h1>
                <StartButton
                    innerText="ClIcK HeRe FoR HeLp!"
                    to="/help" />
            </div>
    )
}

export default RealHelp;
