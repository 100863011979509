import { Link } from 'react-router-dom'
import styles from '../styles/Categories.css'
import StartButton from '../components/StartButton';

function Categories() {
    return (
        <div className='categories-parent'>
            <h1 className='categories-header'>Pick your category</h1>
            <div className='categories-container'>
                <div className='phone-category-container category'>
                    <StartButton innerText="Phone" to="/phone/1" />
                </div>
                <div className='date-category-container category'>
                <StartButton innerText="Date" to="/date/1" />
                </div>
                <div className='number-category-container category'>
                <StartButton innerText="Numbers" to="/number/1" />
                </div>
                <div className='gender-category-container category'>
                <StartButton innerText="Gender" to="/gender/1" />
                </div>
                <div className='names-category-container category'>
                <StartButton innerText="Names" to="/name/1" />
                </div>
            </div>
        </div>
    )
}

export default Categories;
