import CategoryHeader from "../../../components/CategoryHeader";
import "../../../styles/gender1.css";
import ReactSlider from "react-slider";
import React, { useState } from "react";

function Gender1() {
  const [sliderValue, setSliderValue] = useState(50);
  const [maleSliderValue, setMaleSliderValue] = useState(0);
  const [femaleSliderValue, setFemaleSliderValue] = useState(100);

  const HandleFemaleSliderChange = (newValue) => {
    setMaleSliderValue(100 - newValue);
    setFemaleSliderValue(newValue);
  };

  const HandleMaleSliderChange = (newValue) => {
    setMaleSliderValue(newValue);
    setFemaleSliderValue(100 - newValue);
  };

  return (
    <div>
      <CategoryHeader
        headerText="Choose Gender"
        pageNumber={1}
        category="gender"
      />
      <div className="gender-sliders-container">
        <div className="male-container">
          <h3 className="slider-name">Man</h3>
          <ReactSlider
            className="horizontal-slider male"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            value={maleSliderValue}
            onChange={HandleMaleSliderChange}
          />
        </div>
        <div className="female-container">
          <h3 className="slider-name">Woman</h3>
          <ReactSlider
            className="horizontal-slider female"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            value={femaleSliderValue}
            onChange={HandleFemaleSliderChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Gender1;
