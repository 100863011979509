import { Link } from 'react-router-dom'
import styles from '../../src/styles/Done.css'
import StartButton from '../components/StartButton';

function Done() {
    return (
        <div className='done-parent'>
            <h1>Done!</h1>
            <h2>You finished this category!</h2>
            <StartButton
                innerText="Back to categories"
                to="/categories"
            />
        </div>
    )
}


export default Done;
