import CategoryHeader from '../../../components/CategoryHeader'
import React, { useState } from 'react';
import Select from 'react-select';
import '../../../styles/phone1.css'

function setCharAt(str,index,chr) {
    if(index > str.length-1) return str;
    return str.substring(0,index) + chr + str.substring(index+1);
}

function formatPhoneNumber(phoneString) {
    var firstPart = phoneString.substring(0, 2)
    var secondPart = phoneString.substring(2, 5)
    var thirdPart = phoneString.substring(5, 8)
    var fourthPart = phoneString.substring(8, 12)

    return "+ " + firstPart + " (" + secondPart + ") " + thirdPart + " - " + fourthPart
}

const defaultOption = { value: '0', label: '0' };
const dropdownOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '0', label: '0' },
];

function Phone1() {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("000000000000");
    const dropdowns = [];
    for (let i = 0; i < 12; i++) {
        var randomOptions = dropdownOptions.sort(() => Math.random() - 0.5)
        dropdowns.push(
            <Select
                key={i}
                options={randomOptions}
                value={selectedOptions[i]}
                defaultValue={defaultOption}
                isSearchable={false}
                onChange={(selectedOption) => handleDropdownChange(selectedOption, i)}
                className='dropdown'
                classNamePrefix="dropdown"
            />
        );
    }

    const handleDropdownChange = (selectedOption, dropdownIndex) => {
        const updatedOptions = [...selectedOptions];
        updatedOptions[dropdownIndex] = selectedOption;
        setSelectedOptions(updatedOptions);

        var index = parseInt(dropdownIndex)
        setSelectedPhoneNumber(
            setCharAt(selectedPhoneNumber, index, selectedOption["value"])
        )
    };

    return (
        <div>
            <CategoryHeader
                headerText="Input Phone Number"
                pageNumber={1}
                category="phone" />
            <div className='dropdowns-container'>
                {dropdowns}
            </div>
            <div className='phone-number-selected'>
                <h3>{formatPhoneNumber(selectedPhoneNumber)}</h3>
            </div>
        </div>
    )
}

export default Phone1;
