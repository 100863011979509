import BackButton from './BackButton';
import NextButton from './NextButton';
import '../styles/CategoryHeader.css'

var CATEGORY_MAX = {
    "phone": 2,
    "gender": 2,
    "name": 1,
    "number": 2,
    "date": 1
}

function CategoryHeader({ category, headerText, pageNumber}) {
    const noBack = (pageNumber < 2);
    var maxPageNumber = CATEGORY_MAX[category]

    return (
        <div className="category-header-wrapper">
            <div className='category-header-back'>
                <BackButton
                    pageNumber={pageNumber}
                    category={category} />
            </div>
            <h1 className='category-header-text'>{headerText}</h1>
            <div className='category-header-next'>
                <NextButton
                    maxPageNumber={maxPageNumber}
                    pageNumber={pageNumber}
                    category={category} />
            </div>
        </div>
    )
}

export default CategoryHeader;
