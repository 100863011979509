import { Link } from "react-router-dom";
import "../styles/StartButton.css";

const StartButton = ({innerText, to}) => {
  return (
    <div className="start-button-wrapper">
        <Link to={to} className="start-button">
            {innerText}
        </Link>
    </div>
  );
};

export default StartButton;
