import { Link } from 'react-router-dom'
import styles from '../../src/styles/Help.css'
import StartButton from '../components/StartButton'

var HELP_ORDER_OBJECT = [
    {
        buttonText: "Click for REAL help!"
    }
]

function Help() {
    return (
        <div className='help-header'>
            <h1>Help</h1>
            <StartButton
                innerText={HELP_ORDER_OBJECT[0].buttonText}
                to="/real-help" />
        </div>
    )
}

export default Help;
