import { Link } from "react-router-dom";
import "../styles/Header.css";
import haComLogo from "../assets/HAcom_logo.png"

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="header-content">
        <div className="logo-wrapper">
          <Link to="/">
            <img src={haComLogo} alt="HA, logo" className="ha-com-logo" />
          </Link>
        </div>
        <span className="help-wrapper">
          <Link to="/help">Help!</Link>
        </span>
      </div>
    </div>
  );
};

export default Header;
