import React, { useState } from 'react';
import 'react-phone-number-input/style.css'
import '../../../styles/phone2.css'
import PhoneInput from 'react-phone-number-input/input'
import CategoryHeader from "../../../components/CategoryHeader";

const DEFAULT_TYPE_PHONE_TEXT = "Type Phone Number"

function Phone2() {
  const [value, setValue] = useState()
  const [isDisabledInput, setIsDisabledInput] = useState()
  const [titleText, setTitleText] = useState(DEFAULT_TYPE_PHONE_TEXT)

  const handleInputChange = async (event) => {
    setTitleText("Please wait...")
    await setValue(event.value)
    await setIsDisabledInput(true)
    await new Promise(resolve => setTimeout(resolve, 5000));
    await setIsDisabledInput(false)
    await setTitleText(DEFAULT_TYPE_PHONE_TEXT)
  }

  return (
    <div>
      <CategoryHeader
        headerText="Input Phone Number again"
        pageNumber={2}
        category="phone" />
      <h3>
        {titleText}
      </h3>
      <div>
      <PhoneInput
        className='delay-phone-input'
        placeholder="Enter phone number"
        country="US"
        value={value}
        onChange={handleInputChange}
        disabled={isDisabledInput}
        />
      </div>
    </div>
  );
}

export default Phone2;
