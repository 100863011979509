import { Link } from "react-router-dom";
import { useState } from "react";
import "../styles/BackButton.css"
import backButton from "../assets/back_button.png"

// TODO handle if zero-th page
const BackButton = ({ pageNumber, category }) => {
    var routeString = "/" + category + "/" + (pageNumber-1);

    if (pageNumber < 2) {
        routeString = "/categories"
    }

    return (
        <div>
            <Link to={routeString}>
                <img
                    src={backButton}
                    alt="back button"
                    className="back-button"
                     />
            </Link>
        </div>
    );
}

export default BackButton;
