import CategoryHeader from '../../../components/CategoryHeader'
import React, { useState } from 'react';
import Select from 'react-select';
import '../../../styles/name1.css'
import FirstNameData from '../../../../src/assets/FirstNames.json';
import LastNameData from '../../../../src/assets/LastNames.json';

function Name1() {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleDropdownChange = (selectedOption, dropdownIndex) => {
        const updatedOptions = [...selectedOptions];
        updatedOptions[dropdownIndex] = selectedOption;
        setSelectedOptions(updatedOptions);
    };

    return (
        <div>
            <CategoryHeader
                headerText="Select Name"
                pageNumber={1}
                category="name" />
            <h4>
                If you happen to not find your name, please change it to a valid one in the dropdown.
            </h4>
            <div className='name-dropdown-container'>
                <Select
                    key={0}
                    options={FirstNameData.list}
                    value={selectedOptions[0]}
                    isSearchable={false}
                    placeholder={"First Name"}
                    onChange={(selectedOption) => handleDropdownChange(selectedOption)}
                    className='name-dropdown'
                />
                <Select
                    key={1}
                    options={LastNameData.list}
                    value={selectedOptions[1]}
                    isSearchable={false}
                    placeholder={"Last Name"}
                    onChange={(selectedOption) => handleDropdownChange(selectedOption)}
                    className='name-dropdown'
                />
            </div>
            <h4>
                Warning! Opening one of these dropdowns WILL slow down your browser.
            </h4>
        </div>
    )
}

export default Name1;
