import { Link } from 'react-router-dom'
import '../../src/styles/Home.css'
import StartButton from '../components/StartButton';

function Home() {
    return (
        <div>
            <h1 className='home-header'>Home</h1>
            <StartButton
                innerText="Let's Go!"
                to="/categories"
            />
        </div>
    )
}

export default Home;
