import React, { useRef, useEffect, useState } from 'react';
import '../../../styles/number2.css'
import CategoryHeader from "../../../components/CategoryHeader";

function Number2() {
    const canvasRef = useRef(null);
    const [canvasOutputText, setCanvasOutputText] = useState('');
    const [phoneNumberText, setPhoneNumberText] = useState('');
    const [canvasImage, setCanvasImage] = useState('');

    const handleClickClearDrawing = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    }

    const handleClickClearNumber = () => {
        setPhoneNumberText('')
    }

    const handleClickSubmitDrawing = () => {
        var canvasOutputText = "whatever"
        setPhoneNumberText(phoneNumberText + canvasOutputText);
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        console.log(canvasImage)
        context.clearRect(0, 0, canvas.width, canvas.height);
    }


  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;

    const startDrawing = (e) => {
      isDrawing = true;
      [lastX, lastY] = [e.offsetX, e.offsetY];
    };

    const draw = (e) => {
      if (!isDrawing) return;

      context.beginPath();
      context.moveTo(lastX, lastY);
      context.lineTo(e.offsetX, e.offsetY);
      context.stroke();

      [lastX, lastY] = [e.offsetX, e.offsetY];
    };

    const stopDrawing = () => {
      isDrawing = false;
      setCanvasImage(canvasRef.current?.toDataURL('image/png'));
    };

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseup', stopDrawing);
    canvas.addEventListener('mouseout', stopDrawing);

    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseup', stopDrawing);
      canvas.removeEventListener('mouseout', stopDrawing);
    };
  }, []);

  return (
    <div>
      <CategoryHeader
        headerText="Draw each number of your phone number"
        pageNumber={2}
        category="number" />
      <div>
        <div className='drawing-container'>
            <canvas ref={canvasRef} width={250} height={250} className='drawing-canvas' />
            <div>
                <button onClick={handleClickClearDrawing}>Clear Drawing</button>
                <button onClick={handleClickClearNumber}>Clear Phone Number</button>
            </div>
            <div>
                <button onClick={handleClickSubmitDrawing}>Submit Drawing</button>
            </div>
        </div>
        {phoneNumberText}
      </div>
    </div>
  );
}

export default Number2;
